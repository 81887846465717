import React, { useState } from 'react';
import '../styles/Login.css';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { login } from "../actions";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

export default function LoginPage({ route, token, setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();

    if (token) navigate("/");

    let onClickHandler = () => {
        navigate("/signup", {token, setToken});
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await login({
            username,
            password
        });
        setToken(token);
        navigate("/");
    }

    return (
        <>
            <Header token={token} />
            <div className="login-form">
                <h1 style={{"fontFamily": "sans-serif"}}>Please Log In</h1>
                <form onSubmit={handleSubmit}>
                    <div className="field username">
                        <input 
                            onChange={e => setUserName(e.target.value)}
                            type="text" 
                            name="username" 
                            id="username" 
                            placeholder="email@email.com" 
                        />
                    </div>
                    <div className="field password">
                        <input type="password" name="password" id="password" placeholder="password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div><a href="#"> <span className="forgot-pw">Forgot password?</span></a></div>
                    <input type="submit" id="btnLogin" value="Login"/>
                </form>
                <button onClick={onClickHandler}><a href="#"> <span className="singup-link">Oh, not a member? Click here</span></a></button>
            </div>
            <Footer />
        </>
    )
}

LoginPage.propTypes = {
    setToken: PropTypes.func.isRequired
};
