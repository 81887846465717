import React, { useState } from 'react';
import '../styles/Login.css';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { signup } from "../actions";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

export default function SignUp({ route, token, setToken }) {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();

    if (token) navigate("/");

    const handleSubmit = async e => {
        e.preventDefault();
        await signup({
            name,
            email,
            password
        });
        navigate("/login");
    }

    return (
        <>
            <Header token={token} />
            <div className="login-form">
                <h1 style={{"fontFamily": "sans-serif"}}>Sign Up</h1>
                <form onSubmit={handleSubmit}>
                    <div className="field name">
                        <input 
                            onChange={e => setName(e.target.value)}
                            type="text" 
                            name="name" 
                            id="name" 
                            placeholder="name" 
                        />
                    </div>
                    <div className="field username">
                        <input 
                            onChange={e => setEmail(e.target.value)}
                            type="text" 
                            name="email" 
                            id="email" 
                            placeholder="email@email.com" 
                        />
                    </div>
                    <div className="field password">
                        <input type="password" name="password" id="password" placeholder="password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <input type="submit" id="btnLogin" value="Sign Up"/>
                </form>
            </div>
            <Footer />
        </>
    )
}
