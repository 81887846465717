import React, {useState} from 'react';
import Vote from "./Vote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Answer({answer, handleUpVote, handleDownVote}){
    const [saved, setSaved] = useState(answer.saved);

    let handleSave = () => {
        setSaved(saved^true);
    }

    return (
        <div className="answer">
            <img src={answer.imgSrc}/>
            <div className="main-content">
                <p className="title">{answer.title}</p>
                <p className="content'">{answer.content}</p>
                <Vote 
                    voteCount={answer.vote}
                    userVote={answer.userVote}
                />
            </div>
            <button className="save-button" onClick={handleSave}>
              {saved ? (
                <FontAwesomeIcon icon="fa-solid fa-bookmark" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-bookmark" />
              )}
            </button>
        </div>
    );
}