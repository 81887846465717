import React, {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Vote( { upVoteCallback, downVoteCallback, voteCount, userVote }){
    const [vote, setVote] = useState(voteCount);
    const [userVotedState, setuserVotedState] = useState(userVote);

    let handleUpVote = () => {
        if (userVotedState == 1) {
            setVote(vote - 1);
            setuserVotedState(0);
        }
        else if (userVotedState == -1){
            setVote(vote + 2);
            setuserVotedState(1);
        }
        else {
            setVote(vote + 1);
            setuserVotedState(1);
        }
    }

    let handleDownVote = () => {
        if (userVotedState == 1) {
            setVote(vote - 2);
            setuserVotedState(-1);
        }
        else if (userVotedState == -1){
            setVote(vote + 1);
            setuserVotedState(0);
        }
        else {
            setVote(vote - 1);
            setuserVotedState(-1);
        }
    }

    return (
        <div className="vote=wrapper">
            <div className="vote">
                <button
                className="vote-button"
                id="up-vote-button"
                onClick={handleUpVote}
                >
                {userVotedState === 1 ? (
                    <FontAwesomeIcon icon="fa-solid fa-circle-chevron-up" />
                ) : (
                    <FontAwesomeIcon icon="fa-solid fa-angle-up" />
                )}
                </button>
                <span className="vote-count">{vote}</span>
                <button
                className="vote-button"
                id="down-vote-button"
                onClick={handleDownVote}
                >
                {userVotedState === -1 ? (
                    <FontAwesomeIcon icon="fa-solid fa-circle-chevron-down" />
                ) : (
                    <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                )}
                </button>
            </div>
        </div>
    );
}