import React, { Component } from "react";
import Card from "./Card";
import "../styles/Lists.css";

class Lists extends Component {
  constructor(props) {
    super(props)
    this.myRef = props.myRef;
  }

  executeScroll = () => this.myRef.current.scrollIntoView({behavior: "smooth"})

  componentDidUpdate() {
    if (this.myRef) this.executeScroll();
  }

  render() {
    return (
      <div style={{"display": this.props.lists.length == 0 ? "none" : "inherit" }} ref={this.myRef} className="lists section">
        <h3> {this.props.lists.length == 0 || this.props.title }</h3>
        <ul>
          {this.props.lists.map((list, keyId) => (
            <Card
              key={keyId}
              list={list}
              onIncrement={this.props.onIncrement}
              onDecrement={this.props.onDecrement}
              onSave={this.props.onSave}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default Lists;
