import axios from 'axios';

const ROOT_URL = "http://ec2-3-36-74-238.ap-northeast-2.compute.amazonaws.com/api"; /*"https://cors-anywhere.herokuapp.com/http://ec2-3-36-74-238.ap-northeast-2.compute.amazonaws.com/api"; //*/'http://localhost:8000'; //'https://d82c-2603-7000-9d00-2b47-cd48-8ad0-2c95-e6c4.ngrok.io/api';//'https://40a2-64-98-208-143.ngrok.io';//
axios.defaults.baseURL = ROOT_URL;

export async function search(word){
    let path = "/search/";
    let data = {query: word};

    let response = await axios.post(path, data);
    let responseBody = response.data;
    let list = responseBody.Data;
    return list;
}

export async function searchDev(word){
    let path = '/search/' + word;
    let data = {};

    let response = await axios.get(path, data);
    let responseBody = response.data;
    let list = responseBody.data;
    return list;
}

export async function upVote(param){
    let path = "/upvote";
    let data = { feed_id: param.feedId, vote: 1 };

    // TODO: implement
    // let response = await axios.post(path, data);
    // return response;
}

export async function downVote(param){
    let path = "/upvote";
    let data = { feed_id: param.feedId, vote: -1 };

    // TODO: implement
    // let response = await axios.post(path, data);
    // return response;
}

export async function save(param){
    let path = "/like";
    let data = { feed_id: param.feedId};

    // TODO: implement
    // let response = await axios.post(path, data);
    // return response;
}

export async function login(param){
    let path = "/login/";
    let data = param;

    let response = await axios.post(path, data);
    return response.data.token;
}

export async function signup(param){
    let path = "/profile/";
    let data = param;

    let response = await axios.post(path, data);
}