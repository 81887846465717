import React from 'react'

export default function MainTexts() {
    return (
        
        <div className="texts">
          <span className="line" id=" top">Are you feeling okay?
          <p className="line" id="bottom">Tell us about your symtoms</p>
          </span>
          
        </div>
    )
}
