import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

function Header({ token, setToken }) {
    const navigate = useNavigate();

    let loginHandler = () => {
        navigate("/login", {token, setToken});
    }

    let logoutHandler = ()=>{
        setToken(null);
    }

    return (
        <header>
            <div className="content">
                <div className="logo">
                <Link to='/' className="btn-login">
                    <img src={require("../assets/modoo-logo-small.svg").default} width="254px" height="38px" alt="logo" />
                </Link>
                </div>
                <div>
                    {!token ?
                        (<button onClick={loginHandler} to='/login' className="btn-login">Login</button>) :
                        (<button onClick={logoutHandler} className="btn-login">Log out</button>)
                    }
                    <Link to='/contribute' className="btn-login">Contribute</Link>
                </div>
            </div>
        </header>
    )
}

export default Header
