import React, { Component } from "react";

class SubmitBar extends Component {
  onSubmit = (event) => {
    event.preventDefault();
    const { title, sourceName, sourceUrl } = event.target;
    const sourceUrlValue = sourceUrl.value.includes("https://")
      ? sourceUrl.value
      : "https://" + sourceUrl.value;
    const newList = {
      title: title.value,
      sourceName: sourceName.value,
      sourceUrl: sourceUrlValue
    };
    this.props.onSubmit(newList);
  };

  render() {
    if (this.props.keyword) {
      return (
        <div className="submit-container">
          <p>if you have more information, please share here !! </p>

          <form className="submit-bar" onSubmit={this.onSubmit}>
            <input
              type="text"
              className="submit-content-title"
              name="title"
              placeholder="Title"
            />
            <input
              type="text"
              className="submit-source-name"
              name="sourceName"
              placeholder="reference"
            />
            <input
              type="text"
              className="submit-source-url"
              name="sourceUrl"
              placeholder="reference url"
            />
            <button className="search-button"> Share </button>
          </form>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

// Request Syntax (POST)
// {
//     	“userId”: string,
// "query": "a string, comma seperated",
// “contentTitle”: string,  # e.g., “common symptom of covid-19”,
// 	“sourceName”: string,    # e.g., Mayo, …
// 	“sourceUrl”: string,    # www.mayo.com/~~~
// }

export default SubmitBar;
