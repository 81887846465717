import React, { Component } from "react";
import MainTexts from "./MainTexts";


class SearchBar extends Component {
  inputRef = React.createRef();

  onSubmit = (event) => {
    event.preventDefault();
    const word = this.inputRef.current.value;
    word && this.props.onSearch(word);
  };

  render() {
    return (
      
      <form ref={this.formRef} className="search-bar" onSubmit={this.onSubmit}>
        <MainTexts />
        <div className="search">
          <input
            ref={this.inputRef}
            type="text"
            className="search-input"
            placeholder="Please input keywords... (example : Covid-19)"
          />
          <button className="search-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem">
              <path fill="#174BAE" d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/>
            </svg>
          </button>
        </div>
      </form>
    );
  }
}

export default SearchBar;
