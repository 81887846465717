import React, { Component, useState } from "react";
// import fetch from "fetch";
import "./App.css";

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Lists from "./components/Lists";
import SearchBar from "./components/SearchBar";
import SubmitBar from "./components/SubmitBar";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import search from "./actions/search"
import Index from "./views/Index";
import LoginPage from "./views/LoginPage";
import SignUp from "./views/SignUp";
import Contribute from "./views/Contribute";

export default function App() {
  const [token, setToken] = useState();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index token={token} setToken={setToken}/>} />
          <Route path="/login" element={<LoginPage token={token} setToken={setToken}/>} />
          <Route path="/signup" element={<SignUp token={token} setToken={setToken}/>} />
          <Route path="/contribute" element={<Contribute token={token} setToken={setToken}/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
