import React, { Component } from 'react';
import Lists from "../components/Lists";
import SearchBar from "../components/SearchBar";
import SubmitBar from "../components/SubmitBar";
import {search, upVote, downVote, save} from "../actions";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ExpertAnswers from "../components/ExpertAnswers";
import "../styles/Sections.css";

class Index extends Component {
    state = {
        lists: [],
        keyword: ""
    };

    findList = (lists, id) => {
        let matchedList = null;
        lists.forEach((list) => {
            if (list.id === id) matchedList = list;
        })
        return matchedList;
    }

    handleSearch = async (word) => {
        let list = await search(word);
        this.setState({ lists: list, keyword: word });
    };

    handleSubmit = (submittedList) => {
        let lists = [...this.state.lists];
        lists                       .push(submittedList);
        this.setState({ lists, keyword: "" });
    };

    handleUpVote = async (id) => {
        let lists = [...this.state.lists];
        let list = this.findList(lists, id);

        await upVote({ feedId: list.id });

        if (list.userVote < 1){
            list.userVote += 1;
            list.vote += 1;
        }

        this.setState({ lists });
    };

    handleDownVote = async (id) => {
        let lists = [...this.state.lists];
        let list = this.findList(lists, id);

        await downVote({ feedId: list.id });

        if (list.userVote > -1) {
            list.userVote -= 1;
            list.vote -= 1;
        }
        this.setState({ lists });
    };

    handleSave = (id) => {
        let list = this.findList(id);
        list.saved === 0 ? (list.saved = 1) : (list.saved = 0);
        this.setState({ lists });
    };

    render() {
        return(
            <>
                <Header token={this.props.token} setToken={this.props.setToken} />
                <SearchBar onSearch={this.handleSearch} />
                {/* <SubmitBar keyword={this.state.keyword} onSubmit={this.handleSubmit} /> */}
                <Lists
                    lists={this.state.lists.filter(l => l.sourceName == "youtube")}
                    onIncrement={this.handleUpVote}
                    onDecrement={this.handleDownVote}
                    onSave={this.handleSave}
                    myRef={React.createRef()}
                    title={"Top Rated"}
                />
                {this.state.lists.length == 0 || <ExpertAnswers keyword={this.state.keyword}/>}
                <Lists
                    lists={this.state.lists.filter(l => l.sourceName != "youtube")}
                    onIncrement={this.handleUpVote}
                    onDecrement={this.handleDownVote}
                    onSave={this.handleSave}
                    myRef={null}
                    title={"Webpage Info"}
                />
                <Footer />
            </>
        );
    }
}

export default Index;
