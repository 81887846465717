import React from 'react';
import Answer from './Answer';
import '../styles/ExpertAnswers.css';
import doc1Profile from '../assets/doc1.png'
import doc2Profile from '../assets/doc2.jpeg'
import doc3Profile from '../assets/doc3.png'

export default function ExpertAnswers({ keyword }){

    let insomniaData = [
        {
            imgSrc: doc1Profile,
            title: "Types of Insomnia",
            content: "There are two types of insomnia: primary and secondary. You might also hear about ...",
            vote: 382,
            userVote: 1,
            saved: false
        },
        {
            imgSrc: doc2Profile,
            title: "Sleep-onset insomnia",
            content: "Sleep-onset insomnia means you have trouble getting to sleep ...",
            vote: 1324,
            saved: false,
            userVote: -1
        },
        {
            imgSrc: doc3Profile,
            title: "Insomnia Causes",
            content: "Stress related to big life events, like a job loss or change the death of a loved one, divorce, or moving",
            vote: 892,
            saved: false,
            userVote: 0
        },
        {
            imgSrc: "https://www.shareicon.net/data/128x128/2016/08/18/813844_people_512x512.png",
            title: "Secondary causes of insomnia include:",
            content: "Mental health issues like depression and anxiety, pain or discomfort at night ...",
            vote: 111,
            saved: true,
            userVote: -1
        }
    ]

    let covidData = [
        {
            imgSrc: doc2Profile,
            title: "Symptoms may appear 2-14 days after",
            content: "COVID-19 affects different people in different ways. Infected people... ",
            vote: 382,
            userVote: 1,
            saved: false
        },
        {
            imgSrc: doc3Profile,
            title: "Look for warning signs for COVID-19",
            content: "If someone is showing any of these signs, seek emergency medical care immediatelly ...",
            vote: 1324,
            saved: false,
            userVote: -1
        },
        {
            imgSrc: doc1Profile,
            title: "Symptoms of COVID-19",
            content: "Call your medical provider for any other symptoms that are severe or concerning to you...            ",
            vote: 892,
            saved: false,
            userVote: 0
        },
        {
            imgSrc: "https://www.shareicon.net/data/128x128/2016/08/18/813844_people_512x512.png",
            title: "Differences between Flu and COVID-19​ ",
            content: "Fever or feeling feverish/having chills · Cough · Shortness of breath or difficulty breathing...",
            vote: 111,
            saved: true,
            userVote: -1
        }
    ]

    return (
        <div className="expert-section">
            <div className="section">
                <h3>Expert Answers</h3>
                <div className="expert-answers-wrapper ">
                    {keyword.includes("insomnia") ? 
                    insomniaData.map((a) => (
                        <Answer
                            key={a.title}
                            answer={a}
                        />
                    )) :
                    covidData.map((a) => (
                        <Answer
                            key={a.title}
                            answer={a}
                        />
                    ))
                    }
                </div>
            </div>
        </div>
    );
}