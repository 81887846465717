import React, { useState } from 'react';
import '../styles/Login.css';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { login } from "../actions";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

export default function Contribute({token}) {
    const navigate = useNavigate();

    let onClickHandler = () => {
        navigate("/");
    };

    return (
        <>
            <Header token={token} />
            <div className="login-form" style={{"justify-content": "flex-start"}}>
                <h1 style={{"fontFamily": "sans-serif"}}>If you have more information, please share here</h1>
                <form onSubmit={onClickHandler}>
                    <div className="field username">
                        <input 
                            type="text" 
                            name="username" 
                            id="username" 
                            placeholder="Title" 
                        />
                    </div>
                    <div className="field username">
                        <input 
                            type="text" 
                            name="username" 
                            id="username" 
                            placeholder="Reference" 
                        />
                    </div>
                    <div className="field username">
                        <input 
                            type="text" 
                            name="username" 
                            id="username" 
                            placeholder="Reference Url" 
                        />
                    </div>
                    <div>
                        <textarea
                            type="text"
                            className="submit-source-url"
                            name="sourceUrl"
                            placeholder="Comment"
                            rows="20"
                            style={{"width": "500px"}}
                        />
                    </div>
                    <input type="submit" id="btnLogin" value="Submit"/>
                </form>
            </div>
            <Footer />
        </>
    )
}
