import React, { Component } from "react";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {upvote} from "../actions";
import Vote from "./Vote";

class Card extends Component {
  
  customFetcher = async (url) => {
    const response = await fetch(`https://socal-hackathon-rlp-proxy.herokuapp.com/v2?url=${url}`);
    const json = await response.json();
    return json.metadata;
  };

  constructor(props) {
    super(props);
    this.saved = React.createRef();
  }

  handleUpVote = () => {
    this.props.onIncrement(this.props.list.id);
  };

  // TODO: should up/down vote pass +1/-1 as param?
  handleDownVote = () => {
    this.props.onDecrement(this.props.list.id);
  };
  handleSave = () => {
    this.props.onSave(this.props.list.id);
  };

  render() {
    const {
      id,
      contentTitle,
      sourceName,
      sourceUrl,
      sourceOrder,
      previewText,
      popularityName,
      popularityValue,
      contentContributorId,
      contentContributorScore,
      createdAt,
      vote,
      userVote,
      saved
    } = this.props.list;

    return (
      <div className="list-container">
        <div className="list-contents">
          <LinkPreview url={sourceUrl} imageHeight="210px" width="365px" height="270px" />
          <div className="contribute-box">
            <span className="popularityName">
              {popularityName} : {popularityValue}
            </span>
            {/* <span className="contentContributorId">
              contributed by {contentContributorId} at {createdAt}{" "}
            </span> */}
          </div>
          <Vote 
            voteCount={vote}
            userVote={userVote}
          />
        </div>
        {/* <div className="side-box">
          <div className="vote-box">
            <button className="save-button" onClick={this.handleSave}>
              {this.props.list.saved === 1 ? (
                <FontAwesomeIcon icon="fa-solid fa-heart" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-heart" />
              )}
            </button>
            <button
              className="vote-button"
              id="up-vote-button"
              onClick={this.handleUpVote}
            >
              {this.props.list.userVote === 1 ? (
                <FontAwesomeIcon icon="fa-solid fa-circle-chevron-up" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              )}
            </button>
            <span className="vote-count">{vote}</span>
            <button
              className="vote-button"
              id="down-vote-button"
              onClick={this.handleDownVote}
            >
              {this.props.list.userVote === -1 ? (
                <FontAwesomeIcon icon="fa-solid fa-circle-chevron-down" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              )}
            </button>
          </div>
        </div> */}
      </div>
    );
  }
}

// to do : 1) click button --> change values of vote, favorite, change icons, send values to backend
//         2) build backend with user db, saved db {userId, dataId}
export default Card;

{
  /* <IconButton key="tableModeButton" 
  onTouchTap={() => { 
     this.setState( prevState => {isCardView: !prevState.isCardView} ) }
  }
  iconClassName={this.state.isCardView ? "view_module" : "list"} />
</IconButton> */
}
