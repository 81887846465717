import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="content">
                <div className="logo">
                    <img src={require("../assets/modoo-logo-small.svg").default} width="160px" alt="logo" />
                </div>
                <button className="btn about">About us</button>
                <button className="btn community">Community</button>
                <button className="btn contact">Contact</button>
                <div className="hackathon">© Modoo health, hackathon</div>
            </div>
        </footer>
    )
}
